import React from "react";
import { Layout } from "./src/components/Layout";
// import LanguageDetector from "i18next-browser-languagedetector";

// import "./src/styles/global.css";

export const registerServiceWorker = () => true;

const $ = require("jquery");
// require("slick-carousel");
// const { isAndroid, isMobile } = require("react-device-detect")
// const { isAndroid } = require("react-device-detect");
const FontFaceObserver = require("fontfaceobserver");
const Cookies = require("universal-cookie");
const { cookieScriptReload } = require("./src/cookieScriptReload.js");
const ReactDOM = require("react-dom/client");

/// ================================= descargar como app =================================
// let deviceDetectPromise; // Store the promise

// async function addToMobile() {
//   if (!deviceDetectPromise) {
//     deviceDetectPromise = import("react-device-detect");
//   }
//   const { isAndroid } = await deviceDetectPromise;
//   let deferredPrompt;
//   const addBtn = document.querySelector(".add-button");
//   const delBtn = document.querySelector(".cancel-button");
//   const square = document.getElementById("square");
//   async function prompEvent(event) {
//     // Prevent Chrome 67 and earlier from automatically showing the prompt
//     event.preventDefault();
//     //if it already has been init, then return and dont show the  square again
//     if (localStorage.getItem("init") === "true" || !isAndroid) {
//       return window.removeEventListener("beforeinstallprompt", prompEvent);
//     }
//     if (!square) return;
//     setTimeout(() => {
//       square.style.display = "flex";
//       square.style.flexDirection = "column";
//       square.style.alignItems = "center";
//       square.style.justifyContent = "space-evenly";
//       // Stash the event so it can be triggered later.
//       deferredPrompt = event;
//     }, 30000);
//   }
//   function clickAddEvent() {
//     square.style.display = "none";
//     // Show the prompt
//     deferredPrompt.prompt();
//     localStorage.setItem("init", "true");
//     // remove listeners now
//     window.removeEventListener("beforeinstallprompt", prompEvent);
//     delBtn.removeEventListener("click", clickCancelEvent);
//   }
//   function clickCancelEvent() {
//     square.style.display = "none";
//     localStorage.setItem("init", "true");
//     // remove listeners now
//     window.removeEventListener("beforeinstallprompt", prompEvent);
//     addBtn.removeEventListener("click", clickAddEvent);
//   }
//   window.addEventListener("beforeinstallprompt", prompEvent);
//   //Installation must be done by a user gesture! Here, the button click
//   addBtn && addBtn.addEventListener("click", clickAddEvent);
//   delBtn && delBtn.addEventListener("click", clickCancelEvent);
// }

function trackPageVisit_Connectif() {
  const triggeredFunction = () =>
    window.connectif.managed.sendEvents([{ type: "page-visit" }]);

  if (window.connectif?.managed?.isInitialized()) {
    triggeredFunction();
  } else {
    document.addEventListener(
      "connectif.managed.initialized",
      function onConnectifInitialized() {
        triggeredFunction();
      },
      { once: true },
    );
  }

  // window.connectif.managed.sendEvents([{ type: "page-visit" }]);
}

// ================================== slick ==================================

let slickPromise; // Store the promise
async function sliderCallers() {
  if (!slickPromise) {
    slickPromise = import("slick-carousel");
    console.log("slick downloaded");
  }

  await slickPromise;

  if (!slickPromise) {
    console.log("no slick downloaded");
    return;
  }

  $(".autoplay")
    .not(".slick-initialized")
    .slick({
      dots: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 2000,
      centerMode: false,
      variableWidth: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            dots: true,
          },
        },
        {
          breakpoint: 599,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
          },
        },
      ],
    });
  $(".autoplay2").not(".slick-initialized").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    asNavFor: ".slider-nav",
  });
  $(".slider-nav")
    .not(".slick-initialized")
    .slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      asNavFor: ".autoplay2",
      dots: false,
      centerMode: false,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: false,
          },
        },
        {
          breakpoint: 599,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: false,
          },
        },
      ],
    });
  $(".autoplay5")
    .not(".slick-initialized")
    .slick({
      dots: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      infinite: true,
      autoplay: false,
      autoplaySpeed: 2000,
      centerMode: false,
      variableWidth: true,
      arrows: false,
      customPaging: function (slider, i) {
        // var thumb = $(slider.$slides[i]).data()
        var num = i + 1;
        return "<a>" + num + "</a>";
      },

      responsive: [
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: true,
          },
        },
        {
          breakpoint: 599,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
          },
        },
      ],
    });
}

export const onClientEntry = () => {
  const font = new FontFaceObserver("Plus Jakarta Sans", {
    weight: 400,
  });

  font.load().then(
    function () {
      document.body.classList.add("font-loaded");
      console.log("Plus Jakarta Sans available!!");
    },
    function () {
      console.log("Plus Jakarta Sans is not available");
    },
  );

  function getCookieValue(name) {
    const matchingCookie = new Cookies();
    return matchingCookie?.get(name) || null;
  }

  function getCookieConsentValue(name) {
    const matchingCookie = new Cookies();
    return !!matchingCookie?.get(name);
  }

  const generateCookie = ({ consent }) => {
    const date = new Date();
    const days = 90;
    const expires = new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
    // for testing
    // document.cookie = `sendity-com=${JSON.stringify(
    //   consent,
    // )}; path=/; expires=${expires.toUTCString()} domain=.sendity-test.com; sameSite=strict;`;
    document.cookie = `sendity-com=${JSON.stringify(
      consent,
    )}; path=/; expires=${expires.toUTCString()} domain=.sendity.com; sameSite=strict;`;
  };

  const cookieConsentModeExist = getCookieConsentValue("sendity-com_consent");
  function gtag() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(arguments);
  }

  if (!cookieConsentModeExist) {
    window.dataLayer = window.dataLayer || [];

    const consentObj = {
      functionality_storage: "granted",
      ad_storage: "denied",
      security_storage: "granted",
      analytics_storage: "denied",
      personalization_storage: "granted",
      ad_user_data: "denied",
      ad_personalization: "denied",
    };

    //let know consent to google
    gtag("consent", "default", consentObj);
    //generate cookie passport
    generateCookie({ consent: consentObj });
  } else {
    // use passport cookie and let know consent to google
    gtag("consent", "default", getCookieValue("sendity-com"));
  }
  // Add Google Tag Manager script here
  const initializeGTM = () => {
    if (window.dataLayer) return;
    return (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l !== "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", "GTM-MW53NHG");
  };
  initializeGTM();
  cookieScriptReload();
};

//cookieScriptReload();

function handleWhatsAppScript() {
  if (typeof window !== "undefined" && window.self === window.top) {
    // console.log("Page is not inside an iframe. Rendering WhatsApp script.");

    // Check if the script already exists to avoid adding it multiple times
    if (!document.getElementById("chatgusWhatsapp")) {
      const guschatWaPhone = "34613028636";
      const guschatWaMessage = "Hola";
      const script = document.createElement("script");
      script.src =
        "https://chat.gus.chat/whatsapp/button.min.js?phone=" +
        guschatWaPhone +
        "&message=" +
        guschatWaMessage;
      script.id = "chatgusWhatsapp";
      document.body.appendChild(script);
    }
  } else {
    // console.log(
    //   "Page is inside an iframe. WhatsApp script will not be rendered.",
    // );
  }
}

export const onInitialClientRender = () => {
  $(document).ready(function () {
    handleWhatsAppScript();

    window.addEventListener("load", () => {
      sliderCallers();
    });

    var lastScrollTop = 600;
    $(window).scroll(function (event) {
      var st = $(this).scrollTop();
      if (st < lastScrollTop) {
        // downscroll code
        $(".scorll-btn").removeClass("ok");
      } else {
        // upscroll code
        $(".scorll-btn").addClass("ok");
      }
      // lastScrollTop = st
    });
    setTimeout(function () {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
      //document.getElementById("header").classList.remove("stick_header")
    }, 200);

    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();

        const target = document.querySelector(this.getAttribute("href"));
        const topOffset = -100;

        const targetTop =
          target.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({
          top: targetTop + topOffset,
          behavior: "smooth",
        });
      });
    });
  });
};

export const onRouteUpdate = ({ location, ...props }) => {
  $(document).ready(() => {
    const { pathname } = location;

    trackPageVisit_Connectif();
    handleWhatsAppScript();

    let isHome = false;
    if (pathname === "/" || pathname === "/en/") {
      isHome = true;
    }

    if ($("#pageSimpel").length || $("#blog").length || $("#post").length) {
      $("header").addClass("stick_header");
    }
    $(window).scroll(function () {
      if ($(this).scrollTop() > 1) {
        $("header").addClass("stick_header");
        $("header#header .langmenu_selected-lang").css(
          "--custom-color",
          "#000",
        );
      } else {
        $("header").removeClass("stick_header");
        $("header#header .langmenu_selected-lang").css(
          "--custom-color",
          isHome ? "#000" : "#fff",
        );
        if ($("#blog").length || $("#pageSimpel").length || $("#post").length) {
          $("header").addClass("stick_header");
        }
      }
    });

    $(".header-rgt").clone().appendTo(".side-bar");

    // addToMobile();
    sliderCallers();
  });
};

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};

export const wrapPageElement = ({ props, element }, o) => {
  const { location } = props;
  sliderCallers();

  // const languageDetector = new LanguageDetector(null, {
  //   order: ["navigator"],
  // });

  // Get the user's preferred language
  // const userLanguage = languageDetector.detect();

  const { pathname } = location;

  const lang = () => {
    if (pathname.split("/").includes("en")) {
      return "en";
    }
    return "es";
  };

  // findUrlByPathname({ pathname, lang: lang() });
  // const isBrowser = typeof document !== "undefined";
  // if (
  //   (isBrowser && pathname.split("/").includes("calculadora")) ||
  //   (isBrowser && pathname.split("/").includes("calculadora-recargas")) ||
  //   (isBrowser && pathname.split("/").includes("blog"))
  // ) {
  //   document.getElementsByClassName("chatgusWhatsappBtn")[0]?.remove();
  // }
  // Function to handle WhatsApp script loading based on iframe detection

  return (
    <Layout location={location} lang={lang()}>
      {/* <div className="cn_page_category" style={{ display: "none" }}>
        {lang() === "es" ? pathname.split("/")[1] : pathname.split("/")[2]}
      </div> */}
      {element}
    </Layout>
  );
};

//The APIs wrapPageElement and wrapRootElement exist in both the browser and Server-Side Rendering (SSR) APIs.
//You generally should implement the same components in both gatsby-ssr.js and gatsby-browser.js
//so that pages generated through SSR are the same after being hydrated in the browser.
