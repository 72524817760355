import React, { Children, Suspense, cloneElement, lazy } from "react";

// import { AddToHomeScreenButton } from "../addToHomeScreenButton/AddToHomeScreenButton";
import { Header } from "./Header";
import { Footer } from "./Footer";

import "./layout.css";

import "../../styles/bootstrap.css";
import "../../styles/style.css";
import "../../styles/responsive.css";
import "../../styles/slick-theme.css";
import "../../styles/slick.css";
import "../../styles/scrollbar.css";

const CookieConsent = lazy(() => import("../Cookies"));
export const Layout = ({ children, location, lang }) => {
  const { pathname } = location;
  const isHome = pathname === "/" || pathname === "/en/";
  const allowedUrls = ["/enviar-dinero", "/en/send-money"];

  //check if layout children has langSwitchURL
  const translatedURL = children?.props?.pageContext?.langSwitchURL;

  const contentTypePage = children?.props?.pageContext?.id?.includes("Page_");

  //console.log({ w: children?.props });

  // in case there doesnt exist a blog with translation, take me to content index
  // if blog then /blog/ or /en/blog
  const isEnglishContent = location.pathname.includes("/en/");
  let pathSegments = location.pathname.split("/");

  let indexContent = isEnglishContent
    ? `/${pathSegments[2]}/`
    : `/en/${pathSegments[1]}/`;

  if (contentTypePage) {
    indexContent = lang === "en" ? "/en/" : "/";
  }

  const langSwitchURL = translatedURL || indexContent;
  //console.log({ langSwitchURL, translatedURL, indexContent });

  const isBannerAllowed =
    isHome || allowedUrls.find((url) => pathname.includes(url));

  //------ iframe ----
  const isIframePage =
    pathname === "/calculadora/" || pathname === "/calculadora-recargas/";

  if (isIframePage) {
    return (
      <main>
        <Suspense fallback={null}>
          {Children.map(children, (child, index) =>
            cloneElement(child, { key: index, lang }),
          )}
        </Suspense>
      </main>
    );
  }
  return (
    <>
      {/* <AddToHomeScreenButton /> */}
      <Header
        location={location}
        isHome={isHome}
        lang={lang}
        bannerDisplay={isBannerAllowed}
        langSwitchURL={langSwitchURL}
      />
      <main>
        <Suspense fallback={null}>
          {Children.map(children, (child, index) =>
            cloneElement(child, { key: index, lang }),
          )}
        </Suspense>
      </main>
      <Footer lang={lang} location={location} langSwitchURL={langSwitchURL} />
      <Suspense fallback={null}>
        <CookieConsent lang={lang} location={location} />
      </Suspense>
    </>
  );
};
